<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import VueApexCharts from "vue3-apexcharts";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      title: "Dashboard",
      items: [

        {
          text: "Dashboard",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      sso_user : JSON.parse(localStorage.getItem('sso_user')),
      sso_user_detail : JSON.parse(localStorage.getItem('sso_user_detail')),
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      chartOptions: {
        chart: {
          id: "vue-chart",
          toolbar: {
            show: false,
          },

        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Agst"],
          labels: {
            show: false, // Hide x-axis labels
          },

        },
        dataLabels: {
          enabled: false
        },

      },
      chartSeries: [
        {
          name: "Pendaftar",
          data: [30, 40, 45, 50, 49, 60, 70],
        },
        {
          name: "Pendaftar Tahun lalu",
          data: [20, 30, 35, 40, 39, 50, 62],
        },
        {
          name: "tervertifikasi",
          data: [10, 20, 25, 40, 20, 50, 10],
        },
        {
          name: "terjadwal",
          data: [20, 30, 35, 40, 39, 50, 62],
        },
      ],
      chartOptions2: {
        chart: {
          id: "vue-chart",
          toolbar: {
            show: false,
          },

        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },

        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Agst"],
          // hide x-axis labels
          labels: {
            show: false,
          },
        },
      },
      chartSeries2: [
        {
          name: "Pendaftar",
          data: [30, 40, 45, 50, 49, 60, 70],
        },
        {
          name: "Pendaftar Tahun lalu",
          data: [20, 30, 35, 40, 39, 50, 62],
        },
      ],
      jumlahSkema:null,
      jumlahTUK:null,
      jumlahAsesor:null,
      jumlahAsesi:null,
    };
  },
  mounted() {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      location.reload();
    }

    this.getJumlahSkema();
    this.getJumlahTUK();
    this.getJumlahAsesor();
    this.getJumlahAsesi();
  },
  methods: {
    getJumlahSkema(){
        let self = this;

        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-jumlah-skema",
            headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
            var response_data = response.data;
            var response_data_fix = response_data.data;
            if (response_data.meta.code == 200) {
                self.jumlahSkema = response.data.data.referensi;
            } else {
                Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data_fix.data.message,
                });
            }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getJumlahTUK(){
        let self = this;

        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-jumlah-tuk",
            headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
            var response_data = response.data;
            var response_data_fix = response_data.data;
            if (response_data.meta.code == 200) {
                self.jumlahTUK = response.data.data.referensi;
            } else {
                Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data_fix.data.message,
                });
            }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getJumlahAsesor(){
        let self = this;

        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-jumlah-asesor",
            headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
            var response_data = response.data;
            var response_data_fix = response_data.data;
            if (response_data.meta.code == 200) {
                self.jumlahAsesor = response.data.data.referensi;
            } else {
                Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data_fix.data.message,
                });
            }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getJumlahAsesi(){
        let self = this;

        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-jumlah-asesi",
            headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
            var response_data = response.data;
            var response_data_fix = response_data.data;
            if (response_data.meta.code == 200) {
                self.jumlahAsesi = response.data.data.referensi;
            } else {
                Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data_fix.data.message,
                });
            }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

  },
};
</script>
<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="sessionRoleId == '99e6cc59-b0d5-4929-b97f-fbc15e0665b7' || sessionRoleId == '1a38bbdc-3d8d-11ee-8407-0242ac110009'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="alert alert-info mt-3"><i class="fas fa-info"></i> Jika data profil tidak sesuai, harap konfirmasi ke <strong>Admin HC</strong></div>
                </div>
                <div class="col-md-12 border-right">
                    <div>
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h4 class="text-right">Data Profil</h4>
                        </div>
                        <table class="table table-responsive table-md table-bordered table-striped">
                          <tbody>
                              <tr>
                                  <td>Nama</td>
                                  <td>{{ sso_user?.Name}}</td>
                              </tr>
                              <tr>
                                  <td>Posisi</td>
                                  <td>{{ sso_user?.PositionName }}</td>
                              </tr>
                              <tr>
                                  <td>Unit</td>
                                  <td>{{ sso_user?.UnitName }}</td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="row">
          <div class="col-xl">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h4 class="mb-0">{{ jumlahTUK ?? "0" }}</h4>
                    <p class="text-muted fw-medium mb-2">{{ "TUK" }}</p>
                  </div>

                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title">
                      <i :class="`bx bx-copy-alt font-size-24`"></i>
                    </span>
                  </div>
                </div>
                <!-- <div class="text-center">
                  <a href="#" class="text-muted fw-medium">
                    Lihat Data <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div> -->
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-xl">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h4 class="mb-0">{{ jumlahAsesor ?? "0" }}</h4>
                    <p class="text-muted fw-medium mb-2">{{ "ASESOR" }}</p>
                  </div>

                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title">
                      <i :class="`bx bx-copy-alt font-size-24`"></i>
                    </span>
                  </div>
                </div>
                <!-- <div class="text-center">
                  <a href="#" class="text-muted fw-medium">
                    Lihat Data <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div> -->
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-xl">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h4 class="mb-0">{{ jumlahSkema ?? "0" }}</h4>
                    <p class="text-muted fw-medium mb-2">{{ "SKEMA" }}</p>
                  </div>

                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title">
                      <i :class="`bx bx-copy-alt font-size-24`"></i>
                    </span>
                  </div>
                </div>
                <!-- <div class="text-center">
                  <a href="#" class="text-muted fw-medium">
                    Lihat Data <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div> -->
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-xl">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h4 class="mb-0">{{ jumlahAsesi ?? "0" }}</h4>
                    <p class="text-muted fw-medium mb-2">{{ "ASESI" }}</p>
                  </div>

                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title">
                      <i :class="`bx bx-copy-alt font-size-24`"></i>
                    </span>
                  </div>
                </div>
                <!-- <div class="text-center">
                  <a href="#" class="text-muted fw-medium">
                    Lihat Data <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div> -->
              </div>
              <!-- end card-body -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title mx-4">Pendaftaran dan Kandidat tahun 2023</h3>
              <apexchart type="line" :options="chartOptions" :series="chartSeries" width="100%" />
              <p></p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title mx-4">Kemajuan Proses Administratif</h3>
              <h6 class="card-subtitle text-center mt-2 mx-4">Prosentase Proses Asesmen</h6>
              <apexchart type="bar" :options="chartOptions2" :series="chartSeries2" width="100%" />
              <p class="text-center">
                {{ '*) Data Asesi &lt; Data asesmen, karena dimungkinan asesi ikut lebih dari 1 skema' }}
              </p>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
